import React from "react";
import { LastArticles } from "../../components/Blog/components/LastArticles/LastArticles";
import { OrientedOnSolutionsAndPraxisSection } from "../../components/Blog/components/OrientedOnSolutionsAndPraxisSection";
import { TopHeader } from "../../components/Shared/Layout/components/TopHeader/TopHeader";
import Layout from "../../components/Shared/Layout/Layout";

const AllBlogsPage = () => (
  <Layout>
    <TopHeader title="Blog list" />
    <LastArticles limit={-1} />
    <OrientedOnSolutionsAndPraxisSection />
  </Layout>
);

export default AllBlogsPage;
